.af-modal-content button {
  background: transparent;
}
.af-modal-content .af-modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.af-modal-content .af-modal-header button {
  font-size: 18px;
}

.af-modal-content .af-modal-content-inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.af-modal-content .af-modal-content-inputs input {
  margin-bottom: 0 !important;
}
.af-modal-content .af-modal-message-content {
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  color: white;
}
