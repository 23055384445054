.af-view .af-class-custom-footer {
  max-width: 1000px;
  margin: 10px auto;
}
.af-class-custom-footer .af-class-c-footer {
  padding: 0 40px;
}

@media screen and (max-width: 1300px) {
  .af-view .af-class-custom-footer {
    max-width: 700px;
  }
}
